<template>
  <article class="l-page l-page--login">
    <main v-if="!this.$store.state.auth.token" class="l-page__main">
      <section class="l-page__logo">
        <img class="o-image" alt="Vue logo" src="@rs-911/assets/rs911.svg">
        <hr>
        <b-card title="Welcome" sub-title="Introduction">
          <b-card-text>
            Welcome to the Real Safe 911 online portal.  
          </b-card-text>

          <b-card-text>If you are already a Real Safe 911 user log in with the Apple or Google ID you used to register when you installed the app.  </b-card-text>
          <b-card-text>If you're already a Real Safe 911 user click the button below to learn about Real Safe 911.</b-card-text>
          <b-card-text>
            <b-button v-b-modal.modal-show-content-vt variant="primary-alt">
              Watch Video
            </b-button>
          </b-card-text>
        </b-card>
      </section>

      <section class="l-page__body">
        <header class="l-page__header">
          <h1 class="o-title">
            Log in
          </h1>
        </header>

        <b-form class="c-form__login" @submit.stop.prevent="onSubmit">
          <b-form-group id="example-input-group-1" label="Username:" label-for="example-input-1">
            <b-form-input
              id="example-input-1"
              v-model="$v.form.username.$model"
              name="example-input-1"
              aria-describedby="input-1-live-feedback"
            />

            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              This is a required field.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="input-group-11" label="Password:" label-for="input-11">
            <b-form-input
              id="input-1"
              v-model="$v.form.password.$model"
              name="input-1"
              :type="'password'"      
              aria-describedby="live-feedback"
            />

            <b-form-invalid-feedback
              id="live-feedback"
            >
              This is a required field.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-alert :show="loginFailed" variant="danger">
            Login failed: Invalid username and/or password
          </b-alert>

          <b-button type="submit" class="c-button btn-lg" variant="primary">
            Log in
          </b-button>
        </b-form>
			
        <div class="c-form__login-sso">
          <h3 class="o-title">
            OR
          </h3>
          <vue-apple-signin
            color="black"
            :border="true"
            type="sign in"
          />
          <GoogleLogin class="google-btn btn btn-light btn-lg" :params="params" :on-success="onSuccess" :on-failure="onFailure">
            <svg style="height:20px;" viewBox="0 0 533.5 544.3" xmlns="http://www.w3.org/2000/svg"><path d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z" fill="#4285f4" /><path d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z" fill="#34a853" /><path d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z" fill="#fbbc04" /><path d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z" fill="#ea4335" /></svg>
            Sign in with Google
          </GoogleLogin>
          <!-- <GoogleLogin :params="params" :logout-button="true">
					Logout
				</GoogleLogin> -->
        </div>

        <hr>
        <b-button-toolbar justify>
          <b-link v-b-modal.modal-show-content-tc class="card-link">
            Terms and Conditions
          </b-link>
          <b-link v-b-modal.modal-show-content-pp href="#" class="card-link">
            Privacy Policy
          </b-link>
        </b-button-toolbar>
      </section>
      <ShowContent :id="'modal-show-content-tc'" :mdid="'rs911-terms'" :title="'Terms and Conditions'" />
      <ShowContent :id="'modal-show-content-pp'" :mdid="'rs911-privacy'" :title="'Privacy Policy'" />
      <ShowContent :id="'modal-show-content-vt'"
                   :vid-link="'https://cdn-media.realsafeagent.com/911instructions.mp4'" 
                   :title="'Video Tutorial'" />
    </main>
  </article>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { required, minLength } from 'vuelidate/lib/validators';
import { passwordRegex } from '@shared/services/validators';
import GoogleLogin from 'vue-google-login';
import ShowContent from '@shared/components/ShowContent.vue';
Component.registerHooks(['validations']);

@Component({
  components: {
		GoogleLogin,
		ShowContent
  }
})
export default class Login extends Vue {
  $v: any;
  loginFailed = false;
  form = {
    username: null,
    password: null
  }

  params = {
    client_id: process.env.VUE_APP_SSO_CLIENT_ID_GOOGLE
  };

  onSuccess(googleResponse: any) {
    this.$emit('setOverlay', true);
    console.log('google success', googleResponse);
    this.loginFailed = false;
    this.$store.dispatch('auth/googleLogin', googleResponse.getAuthResponse().id_token)
      .then(() =>  this.goToPortal(),
      () => this.loginError());
  }

  onFailure(test: any) {
    console.log(test);
    this.loginError();
  }

  created() {
    if(this.$store.state.auth.loggedIn) {
      this.goToPortal();
    }
  }

  validations() {
    return {
      form: {
        username: {
          required
        },
        password: {
          required,
          passwordRegex,
          minLength: minLength(6),
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      username: null,
      password: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      // return;
    }
    this.$emit('setOverlay', true);
    this.loginFailed = false;
    this.$store.dispatch('auth/login', { username: this.form.username, password: this.form.password})
      .then(() =>  {
        this.goToPortal();
      },
      () => {
        this.loginError();
      });
  }

  goToPortal() {
    this.$emit('setOverlay', false);
    if(this.$route.query.redirect) {
      this.$router.push(this.$route.query.redirect.toString());
    }
    else {
      console.log('Redirect from login screen');
      this.$router.push('/portal');
    }
  }

  loginError() {
    this.loginFailed = true;
    this.$emit('setOverlay', false);
  }
}
</script>

<style scoped>
#appleid-signin {
  height: 50px !important;
}
</style>
